<template>
  <section>
    <h3 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Rollladen / Panoramarollladen <br />
    </h3>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h1 class="text-4xl md:text-6xl pb-10 w-full" style="color: #f27405">
        Panorama <br />
        Das Rollladensystem mit Jalousiefunktion
      </h1>
      <div class="grid grid-cols-2">
        <div class="row-span-4 col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1 my-4">
          <h2
            class="text-2xl pl-5 pb-5 lg:pl-10 lg:pb-3"
            style="color: #033859"
          >
            Funktionalität für mehr Komfort, zeitloses Design und innovative
            Technik
          </h2>
          <p class="px-5 w-full lg:pl-10 lg:pb-3">
            Das Rollladensystem Panorama verbindet mit seiner innovativen
            Jalousiefunktion die Eigenschaften klassischer Rollläden mit den
            Vorteilen reiner Sonnenschutzprodukte. Auf Wunsch bietet es zudem
            den Komfort eines im Behang integrierten Insektenschutzes. Aufgrund
            des verwendeten hochwertigen Materials ist das System besonders
            witterungsfest und langlebig und dient durch seine Stabilität auch
            der Sicherheit.
          </p>
        </div>
        <figure class="col-span-full my-4 lg:my-24">
          <iframe
            class="w-full h-96"
            src="https://www.youtube.com/embed/llOQmf8yQqM"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            alt="panorama rollladensystem video"
          ></iframe>
        </figure>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Varianten">
            <div class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Behangvarianten:
              </span>
              <br />
              <ul class="flex flex-col sm:flex-row">
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-full md:w-1/3">
                    <img
                      src="../assets/img/biroll/Behang Panorama.webp"
                      style="min-width: 10rem"
                      alt="biroll panorama behang berliner rolladen"
                    />
                  </div>
                  Panorama Safe
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-full md:w-1/3">
                    <img
                      src="../assets/img/biroll/Behang Panorama Combi ST.webp"
                      style="min-width: 10rem"
                      alt="berliner rolladen behang panorama combi"
                    />
                  </div>
                  Panorama Combi ST und RG
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-full md:w-1/3">
                    <img
                      src="../assets/img/biroll/Behang Alu ST.webp"
                      style="min-width: 10rem"
                      alt=" berliner rolladen behang panorama alu"
                    />
                  </div>
                  Alu-Mini St und RG
                </li>
              </ul>
            </div>
          </tab>
          <tab title="Aufsatzelementen">
            <div class="text-lg items-center">
              <span class="font-bold"
                >kann zu den losen Behängen auch als montagefertige Elemente
                geliefert werden:
              </span>
              <br />
              <ul class="flex flex-col sm:flex-row pt-4">
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-full md:w-1/3">
                    <img
                      src="../assets/img/biroll/Element AVB Detail.webp"
                      style="min-width: 10rem"
                      alt=" berliner rolladen element avb"
                    />
                  </div>
                  Aluminium-Vorbauelement
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-full md:w-1/3">
                    <img
                      src="../assets/img/biroll/Element HAS Detail.webp"
                      style="min-width: 10rem"
                      alt=" berliner rolladen biroll element"
                    />
                  </div>
                  Hartschaum-Aufsatzelement
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-full md:w-1/3">
                    <img
                      src="../assets/img/biroll/Element KAS Detail.webp"
                      style="min-width: 10rem"
                      alt=" berliner rolladen biroll element kas"
                    />
                  </div>
                  Kunststoff-Aufsatzelement
                </li>
              </ul>
            </div>
          </tab>
          <tab title="Steuerung">
            <div class="text-lg justify-items-center">
              <span class="font-bold"
                >Das Rollladensystem Panorama kann ganz komfortabel mittels
                Motorantrieben bewegt werden.</span
              >
              <br />
              <img
                src="../assets/img/biroll/Bedienung_und_Steuerung.webp"
                alt=" berliner rolladen biroll bedienung steuerung"
              />
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/biroll/Startbild06.webp"),
          alt: "panoramarolladen mit jalousie funktion",
        },
        {
          image: require("@/assets/img/biroll/Startbild01.webp"),
          alt: "panoramarolladen detailsansicht",
        },
        {
          image: require("@/assets/img/biroll/Startbild02.webp"),
          alt: "panoramarolladen detailsansicht",
        },
        {
          image: require("@/assets/img/biroll/Startbild03.webp"),
          alt: "panoramarolladen detailsansicht",
        },
        {
          image: require("@/assets/img/biroll/Startbild04.webp"),
          alt: "panoramarolladen detailsansicht",
        },
        {
          image: require("@/assets/img/biroll/Startbild05.webp"),
          alt: "panoramarolladen detailsansicht fensterfront",
        },
      ],
    };
  },
};
</script>
<style>
button:focus {
  color: #f27405;
}
button::-moz-focus-inner {
  border: 0;
}
</style>
